<script>
  import SocketLanding from "../../socket/socketLandig";
  import store from "../../store/index.js";

  export default {
    props: ["statusModal", "infoModel", "lisentUploadFiles"],
    data() {
      return {
        progress: {
          percentage: 0,
          type: "data",
        },
        form: null,
        btns: null,
        header: null,
        sendingData: {},
      };
    },
    computed: {
      currentView() {
        const waitElement = this.progress.percentage >= 90 && this.progress.percentage < 100;
        const typeSearch = waitElement ? "wait" : this.progress.type;

        if (typeSearch === "wait") {
          this.onLastPosition();
        }

        return this.statusPreview.find(({ type }) => type === typeSearch) || this.statusPreview.find(({ type }) => type === "data");
      },
      statusPreview() {
        const defaultMsg = "Sending...";
        const { personal, photos, video, finish } = this.sendingData;
        return [
          { text: personal || defaultMsg, type: "data" },
          { text: photos || defaultMsg, type: "images" },
          { text: video || defaultMsg, type: "videos" },
          { text: finish || defaultMsg, type: "wait" },
        ];
      },
      dataProgress() {
        const { wait = "", charging = "" } = this.sendingData;
        return { wait, charging };
      },
      truncatedText() {
        return this.currentView.text.length > 40 ? this.currentView.text.substring(0, 40) + "..." : this.currentView.text;
      },
    },
    methods: {
      onLastPosition() {
        this.$refs.loadingElement.classList.remove("modified");
      },
      onProgressFiles() {
        SocketLanding.on("progressFiles", ({ totalProgress, file = {} }) => {
          const { categorie } = file;
          this.progress.percentage = totalProgress;
          this.progress.type = categorie;

          this.lisentUploadFiles(totalProgress);
        });
      },
      calculateHeight() {
        const div = this.$refs.myDiv;
        const rect = div.getBoundingClientRect();
        const bottom = rect.bottom;

        window.scrollTo({ top: window.scrollY + bottom, behavior: "smooth" });
      },
      async initData() {
        const locale = await store.getters.localeLang;
        this.sendingData = locale?.sending || {};
      },
    },
    async mounted() {
      await this.initData();
      this.onProgressFiles();
      this.$nextTick(() => {
        this.$refs.loadingElement.classList.add("modified");
        this.calculateHeight();
      });
    },
  };
</script>

<template>
  <div class="sendingForm" ref="myDiv">
    <div class="sendingForm__load loading" ref="loadingElement">
      <div class="sendingForm__container">
        <div class="sendingForm__preVideo" v-show="infoModel.videoModels && currentView.type === 'videos'">
          <div class="sendingForm__overlay">
            <iconic class="sendingForm__cameraIcon" name="camera" />
          </div>
          <video class="sendingForm__video" :src="infoModel.videoModels" playsinline webkit-playsinline autoplay muted>
            <track label="English" kind="captions" srclang="en" src="" default />
          </video>
        </div>
        <iconic class="sendingForm__icon" v-show="currentView.type === 'data'" name="identity" />
        <div class="sendingForm__photos" v-show="currentView.type === 'images'">
          <img class="sendingForm__img" :src="item" v-for="(item, idx) in infoModel.photos" :key="idx" alt="preview" />
        </div>
        <p class="sendingForm__currentText">{{ truncatedText }}</p>
      </div>
    </div>
    <div class="sendingForm__article">
      <p class="sendingForm__tittle">{{ infoModel.name }} {{ dataProgress.wait }}</p>
      <p class="sendingForm__subTittle">{{ dataProgress.charging }}</p>
    </div>
  </div>
</template>

<style lang="scss">
  .sendingForm {
    position: relative;
    width: 100%;
    height: 41vh;
    margin-top: 20px;
    background: #fff;
    border-radius: 22px;
    &__load {
      @include Flex(row, center, center);
      flex-wrap: wrap;
      position: relative;
      width: 100%;
      height: 70%;
    }
    &__container {
      @include Flex(column, center, center);
      width: 50%;
      position: relative;
      min-height: 100px;
      bottom: 20px;
    }
    &__article {
      width: 100%;
    }
    &__tittle {
      color: #bd0909;
      text-align: center;
      font-family: $sec_font;
      font-size: 30px;
      font-weight: 400;
    }
    &__subTittle {
      color: #000;
      text-align: center;
      font-family: $first_font;
      font-size: 14px;
      font-weight: 500;
    }
    &__photos {
      @include Flex(row, center, center);
      flex-wrap: wrap;
      gap: 8px;
      width: 50%;
      min-width: 150px;
      max-width: 180px;
    }
    &__img {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      object-fit: cover;
    }
    &__icon {
      font-size: 100px;
      color: #d0d0d0;
    }
    &__overlay {
      @include Flex(row, center, center);
      position: absolute;
      width: 50px;
      height: 100%;
      pointer-events: none;
      border-radius: 10px;
      background: rgba(12, 12, 12, 0.35);
      backdrop-filter: blur(2px);
    }
    &__cameraIcon {
      font-size: 20px;
      color: #fff;
    }
    &__preVideo {
      border-radius: 10px;
      width: fit-content;
      height: fit-content;
      position: relative;
      overflow: hidden;
    }
    &__video {
      width: 50px;
      background-color: #000;
    }
    &__currentText {
      color: #272727;
      font-family: $third_font;
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      bottom: -24%;
      text-align: center;
      max-width: 170px;
    }
    .loading {
      &.modified::before {
        display: none;
      }
      &::after {
        width: 220px;
        height: 220px;
      }
    }
    @media screen and (min-width: 980px) {
      height: 100vh;
      margin: 0;
      &__article {
        position: absolute;
        bottom: 25%;
      }
      &__load {
        height: 100%;
      }
      .loading {
        &::after {
          width: 250px;
          height: 250px;
        }
      }
    }
  }
</style>
