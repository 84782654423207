import io from "socket.io-client";

class SocketService {
  constructor(payload) {
    if (SocketService.instance) {
      return SocketService.instance;
    }

    this.socket = null;
    this.host = payload.host;
    this.path = payload.path;
  }

  connect({ query, auth }) {
    if (!this.socket) {
      this.socket = io(this.host, {
        path: this.path,
        auth,
        query,
        transports: ["websocket"],
      });
    }
    return this.socket;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  on(eventName, callback) {
    if (this.socket) {
      this.socket.on(eventName, callback);
    }
  }

  getSocket() {
    return this.socket;
  }
}

export default SocketService;
